<template>
  <van-config-provider :theme-vars="themeVars">
    <div class="home-warp">
      <img src="https://qiufo.oss-cn-shenzhen.aliyuncs.com/images/nijibot.png" />
      <div class="desc">
        <p>您好, 我是尼基波特！</p>
        <p class="small text-muted">支持ChatGTP对话和Midjourney绘画的AI应用</p>
      </div>
      <div>
        <van-button type="primary" block class="mt-3" @click="gotoHelp">查看帮助</van-button>
        <van-button type="primary" block plain class="mt-3" @click="showKfAccount">联系客服</van-button>
      </div>
    </div>
  </van-config-provider>
</template>

<script>
import { Toast } from 'vant';
import { hideWechatOptionMenu } from "@/utils/util";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const themeVars = {
      buttonPrimaryBorderColor: "#7232dd",
      buttonPrimaryBackgroundColor: "#7232dd",
    };
    const gotoHelp = () => {
      router.push({ name: "NijibotHelp" });
    };
    const showKfAccount = () => {
      Toast("客服微信: NijiBro");
    };
    onMounted(() => {
      hideWechatOptionMenu();
    });
    return { themeVars, gotoHelp, showKfAccount }
  }
}
</script>

<style lang="css" scoped>
.home-warp {
  width: 100vw;
  height: calc(100vh - 130px);
  padding: 100px 30px 30px 30px;
  text-align: center;
  vertical-align: middle;
}

.home-warp img {
  width: 50%;
  margin-bottom: 40px;
  border-radius: 50%;
}
</style>
